<template>
  <v-alert
    info
    class="white nearblack--text"
  >
    <v-row
      class="cursor-pointer offwhite nearblack--text font-weight-medium align-center pa-3"
    >
      <v-col>
        <span
          class="px-1"
          @click="goToDocs"
        >
          Release Brief
        </span>
      </v-col>

      <v-spacer />

      <v-tooltip left>
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <div
            class="cursor-pointer"
          >
            <v-col
              class="text-right"
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
            >
              <v-icon
                class="nearblack--text"
                @click="goToDocs"
              >
                notifications_none
              </v-icon>
            </v-col>
          </div>
        </template>

        <span>Release Notes</span>
      </v-tooltip>
    </v-row>

    <v-row
      color="white"
      class="pa-6"
    >
      <v-col>
        <v-row class="body-2 mb-6">
          <span>
            Welcome to the latest release of iAdOps. Below are a few highlights.
          </span>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-row class="font-weight-bold body-2">
              <span class="purple white--text px-2 mr-2">Update</span> Access Table Menus via Double-Click
            </v-row>

            <v-row class="nearblack--text caption pt-1">
              <p>
                Users who prefer double-clicking over right-clicking can now access table menus
                via their preferred method. Right-clicking and double-clicking produce the same outcome,
                a context menu for the row or table header selected.
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-row class="font-weight-bold body-2">
              <span class="coolgray white--text px-2 mr-2">Fix</span> 10-Day History Requests
            </v-row>

            <v-row class="nearblack--text caption pt-1">
              <p>
                The 10-Day History view, available on right-click of Pacing rows, should render
                the expected table after sending a well-formed request to the iAdOps API
                and receiving the expected response.
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-row class="font-weight-bold body-2">
              <span class="purple white--text px-2 mr-2">Update</span> Expose IDs via Column Controls
            </v-row>

            <v-row class="nearblack--text caption pt-1">
              <p>
                Users can expose ID/Code columns via the Column Controls view, available to users
                on right-click of any column header.  This adjusts for recent updates to our
                Column Controls and Export logic, which align the export and web view more closely.
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-row class="font-weight-bold body-2">
              <span class="coolgray white--text px-2 mr-2">Fix</span> Render of 10-Day History's CTR & Viewable Impressions
            </v-row>

            <v-row class="nearblack--text caption pt-1">
              <p>
                The 10-Day History reports' CTR and Viewable Impressions columns should appear as
                percentages and numbers, respectively.
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-row class="font-weight-bold body-2">
              <span class="primary white--text px-2 mr-2">New</span> Select All & Toggle All Options
            </v-row>

            <v-row class="nearblack--text caption pt-1">
              <p>
                Quickly include or exclude all options listed for an input by clicking the
                new "Select All" and "Toggle All" options at the top of every select input's list of options.
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-6 body-2">
          To learn more, visit the complete
          <a
            href="/docs/releases"
            target="_blank"
            class="pl-1 font-weight-medium"
          >
            release notes
          </a>.
        </v-row>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('auth')

export default {
  name: 'WelcomeCard',
  data() {
    return {
      dismissed: null,
    }
  },
  computed: {
    ...mapGetters(['alert', 'isInternalUser']),
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    goToDocs() {
      this.$router.push('/docs/releases')
      this.dismissFeaturePrompt()
    },
    async dismissFeaturePrompt() {
      this.dismissed = true

      const { user } = this
      const { email, personEmail, settings = {} } = user
      const alert = { dismissed: true }
      const doc = { settings: { ...settings, alert } }
      const docId = email || personEmail
      const data = { doc, docId }

      return this.$store.dispatch('auth/updateUserSettings', data)
    },
  },
}
</script>
